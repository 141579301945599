import {environment} from '@environments/environment';
import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TUrlApi} from '@enums/http.enum';
import {Observable} from 'rxjs';
import {IHttpOptions} from 'shared-ng';

// eslint-disable-next-line
type THttpBody = Object[] | Object | FormData | null | any;
type TMethodApi = 'PUT' | 'DELETE' | 'PATCH';

@Injectable({
  providedIn: 'root',
})
export abstract class BaseApiAbstract {
  protected http = inject(HttpClient);

  protected domain = environment.endpointDomain;

  protected get<T>(url: TUrlApi, options?: IHttpOptions): Observable<T> {
    return this.http.request<T>('GET', this.getEndpoint(url), options);
  }

  protected post<T>(url: TUrlApi, body: THttpBody, options?: IHttpOptions): Observable<T> {
    body = this.normaliseParams(body) as THttpBody;
    return this.http.request<T>('POST', this.getEndpoint(url), {...options, body});
  }

  protected put<T>(url: TUrlApi, body: THttpBody, options?: IHttpOptions): Observable<T> {
    return this.http.request<T>(
      'post',
      this.getEndpoint(url),
      this.getPostOptions('PUT', this.normaliseParams(body), options)
    );
  }

  protected delete<T>(url: TUrlApi, body: THttpBody, options?: IHttpOptions): Observable<T> {
    return this.http.request<T>(
      'delete',
      this.getEndpoint(url),
      this.getPostOptions('DELETE', this.normaliseParams(body), options)
    );
  }

  protected getEndpoint(path: TUrlApi): string {
    const [apiUrl, params] = Array.isArray(path) ? path : [path];

    let url: string;
    if (!params) {
      url = apiUrl;
    } else {
      url = apiUrl.replace(/:[^/?&]+/g, (all: string) => `${params[all.replace(':', '')]}`);
    }

    if (url.startsWith('/')) {
      return `${this.domain}${url}`;
    }

    return `${this.domain}/${url}`;
  }

  private getPostOptions(method: TMethodApi, body: THttpBody, options?: IHttpOptions): IHttpOptions {
    return {
      ...options,
      body,
      params: {
        ...options?.params,
        _method: method,
      },
    };
  }

  protected normaliseParams(data: THttpBody, useGetParamArray = false): THttpBody {
    if (data && typeof data === 'object') {
      Object.keys(data).forEach((key) => {
        // if (data[key] === null || typeof data[key] === 'undefined') {
        if (typeof data[key] === 'undefined') {
          delete data[key];
        } else if (data[key] === '') {
          delete data[key];
        } else if (data[key] instanceof Date) {
          data[key] = (data[key] as Date).toISOString();
        } else if (useGetParamArray && Array.isArray(data[key])) {
          data[`${key}[]`] = data[key];
          delete data[key];
        }
      });

      return data;
    } else if (data instanceof FormData) {
      return data;
    }

    return {};
  }
}
