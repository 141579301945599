import {BaseEnvironment} from './base.environment';

export const environment = {
  ...BaseEnvironment,
  production: true,
  endpointDomain: 'https://cloud.fbfast.io/public-api',
  googleClientId: '410016009108-c8gfgk8ukn3dacqhoao3oq34lemjvslu.apps.googleusercontent.com',
  emailSupport: '1074746+suport-fbfast-local@gmail.com',
  googleMapApiKey: 'AIzaSyD9SqSgcBmp8-anq5dalZrxgLOXCZpIwdg',
};
