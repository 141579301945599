import {Injectable} from '@angular/core';
import {BaseApiAbstract} from '@services/base-api.abstract';
import {EEndpointApi} from '@enums/endpoint-api.enum';
import {IFormWithFields, TFormId} from '@interfaces/form';
import {map, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FormService extends BaseApiAbstract {
  form: IFormWithFields;

  find(slug: string): Observable<IFormWithFields> {
    if (slug?.startsWith('/')) {
      slug = slug.substring(1);
    }

    return this.get<IFormWithFields>([EEndpointApi.FindWithSlug, {slug}]).pipe(
      map((response) => {
        this.form = response;
        return response;
      })
      // tap((response) => console.info('FormService.find', response))
    );
  }

  save(formId: TFormId, bodu = {}): Observable<IFormWithFields> {
    if (!formId) {
      throw formId;
    }

    return this.post<IFormWithFields>([EEndpointApi.FormSaveWithFormId, {formId}], bodu).pipe();
  }
}
