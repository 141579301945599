/**
 * API endpoints.
 * Endpoint example WITH PARAMS:
 * DeleteSinglePipeline = 'pipeline/:pipelineId/delete',
 * Usage: this.get([EndpointApi.DeleteSinglePipeline, {pipelineId: 22}]);
 */
export enum EEndpointApi {
  FindWithSlug = '/find/:slug',
  FormSaveWithFormId = '/form-save/:formId',
}
